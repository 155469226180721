import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { APP_BREAKPOINT } from "src/constants/layout";
import type Path from "src/constants/path";

const MobileNavPosition = styled.nav`
  label: MobileNavPosition;
  position: fixed;
  --link-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  background: var(--color-cream);
  background: var(--greys-light-gray, #e6e3db);
  padding-bottom: calc(var(--ion-safe-area-bottom, 0px) + 0px);

  &[data-page="chat"] {
    background: transparent;
  }

  @media (min-width: ${APP_BREAKPOINT}px) {
    display: none;
  }
`;

//const TabNavigation = styled.div`
//  label: TabNavigation;
//  position: fixed;
//  top: 0;
//  left: 0;
//  right: 0;
//  z-index: 9999990;
//  background: #fff;
//  border-bottom: 1px solid var(--nav-border-color);
//
//  > div {
//    margin: 0 auto;
//    max-width: 490px;
//    display: flex;
//    gap: 24px;
//    align-items: center;
//    justify-content: space-between;
//  }
//
//  .nav-item {
//    padding: 8px 0;
//    gap: 10px;
//
//    &:first-child {
//      padding-left: 16px;
//    }
//
//    &:last-child {
//      padding-right: 16px;
//    }
//  }
//
//  .nav-icon {
//    display: none;
//  }
//
//  .nav-label {
//    font-size: 1rem;
//    font-weight: 500;
//    color: var(--nav-label-color);
//    white-space: nowrap;
//  }
//
//  [aria-current="page"] .nav-label {
//    color: var(--nav-label-color-active);
//  }
//
//  @media (min-width: ${APP_BREAKPOINT}px) {
//    display: none;
//  }
//`;

//const BottomAnchor = styled.div`
//  label: BottomAnchor;
//  position: fixed;
//  bottom: 0;
//  left: 0;
//  right: 0;
//`;

const MobileNav: FC<{
  children: React.ReactNode;
  page: Path;
}> = ({ children, page }) => {
  //const [{ partnerStyles }] = useBloc(AppViewCubit);
  //
  //if (partnerStyles) {
  //  return (
  //    <>
  //      <TabNavigation data-page={page}>
  //        <div>{children}</div>
  //      </TabNavigation>
  //      <BottomAnchor id="bottom" />
  //    </>
  //  );
  //}

  return (
    <MobileNavPosition data-page={page} id="bottom">
      {children}
    </MobileNavPosition>
  );
};

export default MobileNav;
